/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// MUI
import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { persistor } from '../../utils/store';
//Components
import ModalSMS from './components/modalSMS';
import ModalEmail from './components/modalEmail';
import QrCodeGenerator from './components/qrCodeGenerator';

//Icons
import { FiShare, FiUser } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { TiUser } from 'react-icons/ti';
import { IoChevronBack } from 'react-icons/io5';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#792ad5',
  backgroundColor: 'white',
  border: '1px solid white',
  borderRadius: '8px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#792ad5',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const QrCode = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState();
  const [openSMS, setOpenSMS] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const [tel, setTel] = useState({
    tel: '',
  });
  const [email, setEmail] = useState({
    email: '',
  });

  // const user = useSelector((state) => state.user.userTeste);
  const user = useSelector((state) => state.user);

  const { empresa } = useParams();

  const empresaInfo = user.empresas.find((i) => i.id === parseInt(empresa));

  useEffect(() => setState('sms'), []);

  const handleOpen = (btn, e) => {
    e.preventDefault();

    if (btn == 'sms') {
      setOpenSMS(true);
    } else if (btn == 'email') {
      setOpenEmail(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn === 'sms') {
      setOpenSMS(false);
    } else if (btn === 'email') {
      setOpenEmail(false);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    navigate('/');
    persistor.purge();
  };

  const storage = localStorage;
  useEffect(() => {
    if (storage && !storage.token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {storage && storage.token ? (
        <>
          <div className='App'>
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  width: '100vw',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    marginTop: '3%',
                    marginRight: '3%',
                    marginLeft: '3%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '40px' }}>
                    {user && user.empresas && user.empresas.length > 1 && (
                      <IconButton
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={() => navigate('/empresas')}
                        color='inherit'
                      >
                        <IoChevronBack color='#333' />
                      </IconButton>
                    )}
                  </div>

                  <img
                    onClick={() => navigate('/empresas')}
                    style={{ width: '140px' }}
                    src='../img/logo/logo.png'
                    alt=''
                  />

                  <div>
                    <IconButton
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={handleMenu}
                      color='inherit'
                    >
                      <FiUser color='#333' />
                    </IconButton>
                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open1}
                      onClose={handleCloseMenu}
                    >
                      <div>
                        <MenuItem onClick={handleSair}>Sair</MenuItem>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
              <header className='App-header'>
                <div style={{ background: '#ffffff', borderRadius: '20px' }}>
                  <QrCodeGenerator empresaInfo={empresaInfo} />
                </div>
                {/* <img src={empresaInfo.qrcode} className='App-logo' alt='logo' /> */}
                {/* <h1>{empresaInfo.nome}</h1> */}
                {/* Funciona em Android( varios numeros ) e IOS ( apenas o primeiro numero)  */}
                {state == 'sms' && (
                  <>
                    <Button
                      onClick={(e) => handleOpen('sms', e)}
                      style={{
                        // color: `${user && user.cor}`,
                        color: '#333',
                        backgroundColor: '#ececec',
                        // border: '1px solid white',
                        borderRadius: '10px',

                        '&:hover': {
                          backgroundColor: 'white',
                          color: `${user && user.cor}`,
                          // Reset on touch devices, it doesn't add specificity
                          '@media (hover: none)': {
                            backgroundColor: 'white',
                          },
                        },
                        minWidth: '70%',
                        marginTop: '20%',
                      }}
                    >
                      <FiShare />
                      <span style={{ marginLeft: '10px', fontWeight: '500' }}>
                        Partilhar
                      </span>
                    </Button>
                  </>
                )}
                {state == 'email' && (
                  <>
                    <Button
                      onClick={(e) => handleOpen('email', e)}
                      style={{
                        // color: `${user && user.cor}`,
                        color: '#333',
                        backgroundColor: '#ececec',
                        // border: '1px solid white',
                        borderRadius: '10px',

                        '&:hover': {
                          backgroundColor: 'white',
                          color: `${user && user.cor}`,
                          // Reset on touch devices, it doesn't add specificity
                          '@media (hover: none)': {
                            backgroundColor: 'white',
                          },
                        },
                        minWidth: '70%',
                        marginTop: '20%',
                      }}
                    >
                      <FiShare />
                      <span style={{ marginLeft: '10px', fontWeight: '500' }}>
                        Partilhar
                      </span>
                    </Button>
                    {/* </a> */}
                  </>
                )}

                {/* Funciona em IOS ( varios numeros ) */}
                {/* <a href='sms:/open?addresses=914508280,987654321?&body= body text here '> */}
                <div style={{ marginTop: '20px' }}>
                  <Button
                    style={{
                      transition: '0.3s',
                      color: state === 'sms' ? 'white' : `#333`,
                      backgroundImage:
                        state === 'sms'
                          ? 'linear-gradient(0.25turn,#34b7b0,#15949f )'
                          : null,
                      backgroundColor: state === 'sms' ? null : '#ececec',
                      '&:hover': {
                        backgroundColor: state === 'sms' ? 'white' : '#ececec',
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '10px 0px 0px 10px',
                    }}
                    onClick={() => setState('sms')}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/* <BiMessageSquareDetail size='2em' /> */}
                      SMS
                    </div>
                  </Button>
                  <Button
                    style={{
                      transition: '0.3s',
                      color: state === 'email' ? 'white' : `#333`,
                      backgroundImage:
                        state === 'email'
                          ? 'linear-gradient(0.25turn,#15949f,#34b7b0 )'
                          : null,
                      backgroundColor: state === 'email' ? null : '#ececec',
                      '&:hover': {
                        backgroundColor:
                          state === 'email' ? 'white' : '#ececec',
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '0px 10px 10px 0px',
                    }}
                    onClick={() => setState('email')}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/* <IoMailOutline size='2em' /> */}
                      Email
                    </div>
                  </Button>
                </div>
              </header>

              <ModalSMS
                openSMS={openSMS}
                handleClose={handleClose}
                state={state}
                setState={setState}
                tel={tel}
                setTel={setTel}
                props={props}
                user={user}
                empresaInfo={empresaInfo}
              />
              <ModalEmail
                openEmail={openEmail}
                handleClose={handleClose}
                state={state}
                setState={setState}
                email={email}
                setEmail={setEmail}
                props={props}
                user={user}
                empresaInfo={empresaInfo}
              />
            </ThemeProvider>
          </div>
        </>
      ) : (
        navigate('/')
      )}
    </>
  );
};

export default QrCode;
