export const authInitialState = {
  // userTeste: {
  //   id: 1,
  //   nome: 'Rodrigo Mercador',
  //   email: 'rodrigo.mercador@ynb.pt',
  //   empresas: [
  //     {
  //       // INFO EMPRESA
  //       id: 1,
  //       nome: 'JAFplus',
  //       website: 'https://jafplus.pt',
  //       qrcode:
  //         'https://ynb.pt/dev/cartoes-pessoais/cp-svg/rodrigo-mercador.svg',
  //       // PARAMETRIZAÇÃO
  //       logotipo: '--- jafplus ---',
  //       corFundo: '#0EA8B4',
  //       corBotoes: '#E6AA16',
  //       corLetras: '#FFFFFF',
  //     },
  //     {
  //       // INFO EMPRESA
  //       id: 2,
  //       nome: 'JAF Const',
  //       website: 'https://construcoes.jaf.com.pt',
  //       qrcode:
  //         'https://ynb.pt/dev/cartoes-pessoais/cp-svg/rodrigo-mercador.svg',
  //       // PARAMETRIZAÇÃO
  //       logotipo: '--- jafConst ---',
  //       corFundo: '#ED8B1D',
  //       corBotoes: '#004680',
  //       corLetras: '#FFFFFF',
  //     },
  //     {
  //       // INFO EMPRESA
  //       id: 3,
  //       nome: 'JAF Ren',
  //       website: 'https://renovaveis.jaf.com.pt',
  //       qrcode:
  //         'https://ynb.pt/dev/cartoes-pessoais/cp-svg/rodrigo-mercador.svg',
  //       // PARAMETRIZAÇÃO
  //       logotipo: '--- jafRef ---',
  //       corFundo: '#10AE96',
  //       corBotoes: '#004680',
  //       corLetras: '#FFFFFF',
  //     },
  //     {
  //       // INFO EMPRESA
  //       id: 4,
  //       nome: 'JAF Gupo',
  //       website: 'https://jaf.com.pt',
  //       qrcode:
  //         'https://ynb.pt/dev/cartoes-pessoais/cp-svg/rodrigo-mercador.svg',
  //       // PARAMETRIZAÇÃO
  //       logotipo: '--- jafGrupo ---',
  //       corFundo: '#4FC8BB',
  //       corBotoes: '#004680',
  //       corLetras: '#FFFFFF',
  //     },
  //   ],
  // },
};
