import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DadosSenhaUtilizador = (hash, setRes) => async (dispatch) => {
  await axios
    .get(`${BASE_URL}/app/signup/${hash}`)
    .then(({ data }) => {
      dispatch({ type: 'GET_DEF_SENHA', payload: data.yData });
      setRes(200);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          setRes(404);
        }
      }
    });
};

export default DadosSenhaUtilizador;
