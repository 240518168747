/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//PACKAGES
import {
  Button,
  TextField,
  FormControl,
  FormHelperText,
  Snackbar,
  IconButton,
  InputAdornment,
} from '@mui/material';
// import { createTheme } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
//ACTIONS
import DefinirSenha from '../../../../utils/redux/actions/senha/definirSenhaAction';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#333',
  backgroundColor: 'white',
  border: '1px solid white',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#333',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  color: 'white !important',
  '& label.Mui-focused': {
    color: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& input': {
      // paddingLeft: '10px !important',
      background: '#4fa3b1',
      color: 'white',
      // opacity: 0.3,
      borderRadius: '10px',
    },
    '& fieldset': {
      // color: 'white !important',
      // color: '#333 !important',
    },

    '&:hover fieldset': {
      borderColor: 'white !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
  },
});

const FormDefinirSenha = ({
  dados,
  validateOnChange = true,
  hash,
  dispatch,
}) => {
  const [user, setUser] = useState({
    password: '',
    confirmarPassword: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const [values_confirmar, setValues_confirmar] = useState({
    amount: '',
    confirmarpassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const navigate = useNavigate();

  //   useEffect(() => fetch(), []);

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });
    setErrorsUser({});

    // if (validateOnChange)
    //   validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePasswordConfirmar = (prop) => (event) => {
    setValues_confirmar({ ...values_confirmar, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });
    setErrorsUser({});

    // if (validateOnChange)
    //   validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPasswordConfirmar = () => {
    setValues_confirmar({
      ...values_confirmar,
      showPassword: !values_confirmar.showPassword,
    });
  };
  const handleMouseDownPasswordConfirmar = (event) => {
    event.preventDefault();
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues)
      temp.confirmarPassword = fieldValues.confirmarPassword
        ? user.password === user.confirmarPassword
          ? ''
          : 'As passwords têm de ser iguais'
        : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const handleSubmit = (e) => {
    e.preventDefault();
    // history.push('/');
    console.log(validationUser());
    if (validationUser()) {
      const ydata = [
        {
          password: user.password,
          confirmarPassword: user.confirmarPassword,
          setup: hash,
        },
      ];

      // console.log(ydata);

      dispatch(DefinirSenha(ydata, handleOpenAlert, navigate)).then(() =>
        handleOpenAlert('green')
      );
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackGreen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity='success'>
          Senha alterada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackYellow}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity='warning'>
          As passwords nao são iguais!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackRed}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity='danger'>
          As passwords nao são iguais!
        </Alert>
      </Snackbar>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '40px',
        }}
      >
        <form
          className='loginForm'
          noValidate
          autoComplete='off'
          // onSubmit={handleSubmit}
        >
          <CssTextField
            id='email'
            label='Email'
            size='small'
            variant='outlined'
            className='emailInput'
            value={dados.email}
            name='email'
            disabled
            //   onChange={handleForm}
            //   {...(errorsUser.email && {
            //     error: true,
            //     helperText: errorsUser.email,
            //   })}
          />

          <FormControl
            onSubmit={handleSubmit}
            variant='outlined'
            {...(errorsUser.password && {
              error: true,
              helpertext: errorsUser.password,
            })}
          >
            <CssTextField
              label='Password'
              id='outlined-start-adornment'
              value={values.password}
              type={values.showPassword ? 'text' : 'password'}
              name='password'
              onChange={handleChangePassword('password')}
              size='small'
              style={{ background: '#4fa3b1' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {values.showPassword ? (
                        <MdVisibility />
                      ) : (
                        <MdVisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              {...(errorsUser.password && {
                error: true,
                // helperText: errorsUser.password,
              })}
            />
            {errorsUser.password && (
              <FormHelperText
                style={{ color: '#d32f2f', borderColor: '#d32f2f' }}
              >
                {errorsUser.password}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            onSubmit={handleSubmit}
            variant='outlined'
            {...(errorsUser.confirmarPassword && {
              error: true,
              // helperText: errorsUser.confirmarPassword,
            })}
            style={{ marginTop: '20px' }}
          >
            <CssTextField
              label='Confirmar Password'
              id='outlined-start-adornment'
              type={values_confirmar.showPassword ? 'text' : 'password'}
              value={values_confirmar.password}
              name='confirmarPassword'
              onChange={handleChangePasswordConfirmar('confirmarPassword')}
              size='small'
              style={{ background: '#4fa3b1' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPasswordConfirmar}
                      onMouseDown={handleMouseDownPasswordConfirmar}
                      edge='end'
                    >
                      {values.showPassword ? (
                        <MdVisibility />
                      ) : (
                        <MdVisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant='outlined'
              {...(errorsUser.confirmarPassword && {
                error: true,
                // helperText: errorsUser.password,
              })}
            />
            {errorsUser.confirmarPassword && (
              <FormHelperText
                style={{ color: '#d32f2f', borderColor: '#d32f2f' }}
              >
                {errorsUser.confirmarPassword}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </div>

      <ColorButton
        variant='contained'
        // color='primary'
        // className='btn'
        type='submit'
        onClick={handleSubmit}
      >
        Definir Senha
      </ColorButton>
      <Link to='/' style={{ color: 'white' }}>
        <p style={{ fontSize: '14px' }}>Voltar para o Login</p>
      </Link>
    </>
  );
};

export default FormDefinirSenha;
