/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#333',
  backgroundColor: 'white',
  border: '1px solid white',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#333',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

const SenhaJaAssociada = ({ dispatch }) => {
  const navigate = useNavigate();

  //   useEffect(() => fetch(), []);

  const loginRedirect = (e) => {
    e.preventDefault();
    navigate('/');
    // Limpar aqui a "senha" no Redux
    dispatch({ type: 'CLEAR_DADOS' });
  };

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <p style={{ color: 'white' }}>
            Este link já foi utilizado ou não existe.
          </p>
        </div>

        <ColorButton
          variant='contained'
          // color='primary'
          // className='btn'
          type='submit'
          onClick={loginRedirect}
        >
          Voltar ao Login
        </ColorButton>
      </ThemeProvider>
    </>
  );
};

export default SenhaJaAssociada;
