import QRCodeStyling from 'qr-code-styling-new';
import React, { useEffect, useRef } from 'react';

const QrCodeGenerator = ({ empresaInfo }) => {
  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    margin: 0,
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.5,
      margin: 0,
    },
    dotsOptions: {
      type: 'dots',
      color: empresaInfo.corFundo,
    },
    backgroundOptions: { color: 'transparent' },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
    },
    cornersSquareOptions: {
      type: 'extra-rounded',
      color: empresaInfo.corFundo,
    },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0',
      },
    },
    cornersDotOptions: { type: 'dot', color: empresaInfo.corFundo },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0',
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: '#ffffff',
        color2: '#ffffff',
        rotation: '0',
      },
    },
    image: empresaInfo.logotipo,
  });

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    qrCode.update({
      data: empresaInfo.cplink,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaInfo]);

  return (
    <>
      <div ref={ref} />
    </>
  );
};

export default QrCodeGenerator;
