import './companies.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

//Components
import CompaniesList from './components/companiesList';
import ForwardCompany from './components/forwardToCompany';

//IMG

const useStyles = makeStyles({
  btnActive: {
    display: 'inline-block',
    width: 140,
    height: 130,
    background: 'white !important',
    boxShadow: '0px 0px 15px 1px #e6e6e6',
    borderRadius: '10px',
    margin: '10px',
  },
});

const Companies = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const empresas = useSelector((state) => state.user.empresas);
  const storage = localStorage;

  return (
    <>
      {storage && storage.token ? (
        <>
          {empresas && empresas.length !== 1 ? (
            <div className='App'>
              <CompaniesList
                classes={classes}
                navigate={navigate}
                empresas={empresas}
              />
            </div>
          ) : (
            <div className='App'>
              <ForwardCompany
                empresaId={empresas && empresas[0] && empresas[0].id}
              />
            </div>
          )}
        </>
      ) : (
        navigate('/')
      )}
    </>
  );
};

export default Companies;
