/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
//PACKAGES

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

//IMAGES
// import Logo from '../../../images/ynb-logo-black.png';

//ACTIONS

import FormRecuperarSenha from './components/formRecuperarSenha';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const RecuperarSenha = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        navigate('/companies')
      ) : (
        <ThemeProvider theme={defaultMaterialTheme}>
          <div className='home'>
            <div className='home-left'>
              {/* <img src={Logo} alt='' className='logo' /> */}
              <div className='login-form'>
                <div className='titulo'>
                  {/* <p style={{ fontSize: '30px' }}>Web App</p> */}
                  <img
                    style={{ width: '100%' }}
                    src='./img/logo/logo_light.png'
                    alt=''
                  />
                </div>
                <p style={{ color: 'white' }}>
                  Preencha o campo abaixo com o email associado à conta que quer
                  recuperar a sua palavra passe.
                </p>
                <br />
                <br />
                <br />
                {/* {dados.length === 0 ? <SenhaJaAssociada /> : null} */}
                <div style={{ width: '100%', marginTop: '-10px' }}>
                  <FormRecuperarSenha
                    onSubmit
                    form='log_in_form'
                    dispatch={dispatch}
                    // dados={dados}
                    // fetch={fetch}
                    hash={hash}
                  />
                </div>
              </div>
              <div className='bottom'></div>
            </div>
            {/* <div className='home-right'></div> */}
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default RecuperarSenha;
