/* eslint-disable no-useless-concat */
import React from 'react';

// PACKAGES

import { createTheme, styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { Button, Modal, Fade } from '@mui/material';

const theme = createTheme();

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  width: '100%',
  color: '#333 !important',
  '& label.Mui-focused': {
    color: '#333 !important',
  },
  '& .MuiInputLabel-root': {
    color: '#333 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#333 !important',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& input': {
      // paddingLeft: '10px !important',
      background: '#ececec',
      color: '#333',
      // opacity: 0.3,
      borderRadius: '10px',
    },
    '& fieldset': {
      // color: 'white !important',
      // color: '#333 !important',
    },

    '&:hover fieldset': {
      borderColor: '#333 !important',
      border: '1px solid #333 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333 !important',
    },
  },
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5] + '!important',
    padding: theme.spacing(3, 3, 5) + '!important',
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  // btnConfirmar: {
  //   width: '100%',
  //   height: '50px',
  //   color: 'white' + '!important',
  //   // backgroundColor: 'rgb(121, 42, 213)' + '!important',
  //   // '&:hover': {
  //   //   backgroundColor: 'rgb(98, 0, 212)' + '!important',
  //   // },
  // },
  btnCancelar: {
    width: '100%',
    height: '50px',
    color: 'rgb(255, 92, 92)' + '!important',
    backgroundColor: 'rgb(255, 92, 92,0.2)' + '!important',
    // '&:hover': {
    //   backgroundColor: 'rgb(121, 42, 213,0.3)' + '!important',
    // },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
};

const ModalEmail = ({
  openEmail,
  handleClose,
  state,
  setState,
  email,
  setEmail,
  user,
  empresaInfo,
  ...props
}) => {
  const { classes } = props;
  const handleChange = (e) => {
    e.preventDefault();
    setEmail({
      ...email,
      [e.target.name]: e.target.value,
    });
  };

  const formattedBody = `Olá, sou o/a ${
    user && user.nome
  }. Pode clicar no link abaixo para acessar ao meu cartão pessoal digital:
${empresaInfo && empresaInfo.cplink}

Com os melhores cumprimentos,
     ${user && user.nome}`;
  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openEmail}
        onClose={(e) => handleClose('email', e)}
        closeAfterTransition
      >
        <>
          <Fade in={openEmail}>
            <div className={classes.paper}>
              <div className='header-modal'>
                <h2 id='transition-modal-title' className='titulo-modal'>
                  Enviar Email
                </h2>
              </div>

              <div>
                <CssTextField
                  id='email'
                  label='Email'
                  name='email'
                  variant='outlined'
                  className='emailInput'
                  size='small'
                  onChange={handleChange}
                />
              </div>

              <br />
              <div className={classes.modalBtns}>
                <a
                  href={
                    `mailto:${
                      email && email.email
                    }?subject=Cartão Pessoal Digital&body= ` +
                    encodeURIComponent(formattedBody)
                  }
                  style={{
                    textDecoration: 'none',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  <Button
                    style={{
                      transition: '0.3s',
                      color: 'white',
                      backgroundImage:
                        'linear-gradient(0.25turn,#15949f,#34b7b0 )',

                      '&:hover': {
                        backgroundColor: 'white',
                      },
                      width: '150px',
                      borderRadius: '10px',
                    }}
                  >
                    Confirmar
                  </Button>
                </a>
                <div
                  style={{
                    textDecoration: 'none',
                    width: '50%',
                    textAlign: 'center',
                  }}
                >
                  <Button
                    onClick={(e) => handleClose('email', e)}
                    style={{
                      transition: '0.3s',
                      color: '#333',
                      backgroundColor: '#ececec',
                      '&:hover': {
                        backgroundColor: '#ececec',
                      },
                      width: '150px',
                      borderRadius: '10px',
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default withStyles(styles)(ModalEmail);
