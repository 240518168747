/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './login.css';
//PACKAGES
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  TextField,
  // createMuiTheme,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { IconButton, InputAdornment } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
//IMAGES
// import Logo from '/logo_light.png';

//COMPONENTS
import SignIn from '../../../utils/redux/actions/authAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000 !important' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#792ad5',
  backgroundColor: 'white',
  border: '1px solid white',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#792ad5',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  color: 'white !important',
  '& label.Mui-focused': {
    color: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& input': {
      // paddingLeft: '10px !important',
      background: '#4fa3b1',
      color: 'white',
      // opacity: 0.3,
      borderRadius: '10px',
    },
    '& fieldset': {
      // color: 'white !important',
      // color: '#333 !important',
    },

    '&:hover fieldset': {
      borderColor: 'white !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
  },
});
const CssOutlinedInput = styled(OutlinedInput)({
  background: '#4fa3b1',
  borderRadius: '10px',
  color: 'white',
});

const Login = ({ validateOnChange = true }) => {
  const [errorsUser, setErrorsUser] = useState({});
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //--------------------------------- validation ------------------------------/
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange)
      validationUser({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForm = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });

    const ydata = [
      {
        user: {
          email: user.email,
          password: user.password,
        },
      },
    ];

    if (validationUser()) {
      dispatch(SignIn(ydata, navigate, handleOpenAlert));
    }
  };
  const storage = localStorage;

  useEffect(() => {
    if (storage && storage.token) {
      navigate('/empresas');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {storage && storage.token ? (
        navigate('/empresas')
      ) : (
        <>
          <Snackbar
            open={openSnackGreen}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='success'>
              Login efetuado com sucesso!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackYellow}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='warning'>
              Por favor, confirme os seus dados novamente.
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackRed}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='error'>
              Houve um problema no servidor.
            </Alert>
          </Snackbar>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className='home'>
              <div className='home-left'>
                {/* <img src={Logo} alt='' className='logo' /> */}
                <div className='login-form'>
                  <div className='titulo'>
                    <img
                      style={{ width: '100%' }}
                      src='./img/logo/logo_light.png'
                      alt=''
                    />
                  </div>
                  <div className='app-description'>
                    <p>Gestão de cartões de visita virtuais</p>
                  </div>

                  <form
                    className='loginForm'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                  >
                    <CssTextField
                      id='email'
                      label='Email'
                      name='email'
                      variant='outlined'
                      className='emailInput'
                      size='small'
                      value={user.email}
                      onChange={handleForm}
                      {...(errorsUser.email && {
                        error: true,
                        helperText: errorsUser.email,
                      })}
                    />
                    {/* <FormControl
                      // className={clsx(classes.margin, classes.textField)}
                      variant='outlined'
                      {...(errorsUser.password && {
                        error: true,
                        // , helperText: errors.password
                      })}
                    >
                      <InputLabel
                        htmlFor='outlined-adornment-password'
                        // style={{ marginTop: '-6px' }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-password'
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name='password'
                        size='small'
                        onChange={handleChangePassword('password')}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Password'
                      />
                      {errorsUser.password && (
                        <FormHelperText style={{ color: 'red' }}>
                          {errorsUser.password}
                        </FormHelperText>
                      )}
                    </FormControl> */}

                    <FormControl
                      onSubmit={handleSubmit}
                      variant='outlined'
                      {...(errorsUser.password && {
                        error: true,
                        helperText: errorsUser.password,
                      })}
                    >
                      <CssTextField
                        label='Password'
                        id='outlined-start-adornment'
                        value={values.password}
                        type={values.showPassword ? 'text' : 'password'}
                        name='password'
                        onChange={handleChangePassword('password')}
                        size='small'
                        style={{ background: '#4fa3b1' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {values.showPassword ? (
                                  <MdVisibility />
                                ) : (
                                  <MdVisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant='outlined'
                        {...(errorsUser.password && {
                          error: true,
                          // helperText: errorsUser.password,
                        })}
                      />
                      {errorsUser.password && (
                        <FormHelperText
                          style={{ color: '#d32f2f', borderColor: '#d32f2f' }}
                        >
                          {errorsUser.password}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <ColorButton
                      // className={classes.btnEntrar}
                      // onClick={handleSubmit}
                      type='submit'
                      style={{ margin: 'auto', marginTop: '30px' }}
                    >
                      Entrar{' '}
                    </ColorButton>
                  </form>

                  <Link to='/recuperarSenha' style={{ color: 'white' }}>
                    <p>Recuperar Password</p>
                  </Link>
                </div>
                <div className='bottom'></div>
              </div>
              {/* <div className='home-right'></div> */}
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default Login;
