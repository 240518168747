import axios from 'axios';
import BASE_URL from '../../BASE_URL';

// New Actions for the app

export const SignIn =
  (ydata, navigate, handleOpenAlert) => async (dispatch) => {
    await axios
      .post(`${BASE_URL}/app/user/login`, { ydata })
      .then(({ data }) => {
        if (data.token) {
          dispatch({ type: 'SIGN_IN', payload: data.user });
          localStorage.setItem('token', data.token);
          navigate('/empresas');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            handleOpenAlert('yellow');
          } else {
            handleOpenAlert('red');
          }
        }
        // console.log('error');
      });
  };

export default SignIn;
