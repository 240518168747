import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { persistor } from '../../../utils/store';
//MUI
import {
  Card,
  Menu,
  MenuItem,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

//Icons
import { FiUser } from 'react-icons/fi';
import { BiSearch } from 'react-icons/bi';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const CssTextField = styled(TextField)({
  borderRadius: '10px',

  '& label.Mui-focused': {
    color: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& input': {
      // paddingLeft: '10px !important',
      background: '#ececec',
      color: '#333',
      // opacity: 0.3,
      borderRadius: '10px',
      border: 'transparent !important',
    },
    '& fieldset': {
      // color: 'white !important',
      // color: '#333 !important',
      border: 'transparent !important',
    },

    '&:hover fieldset': {
      borderColor: 'white !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
  },
});

const CompaniesList = ({ classes, empresas }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCompany = (id) => {
    navigate(`/empresa/${id}`);
  };

  const handleSair = () => {
    // setAnchorEl(null);
    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    navigate('/');
    persistor.purge();
  };

  const [filter, setFilter] = useState('');
  const [noCompanies, setNoCompanies] = useState('');
  const handleChange = (e) => {
    e.preventDefault();

    setFilter(e.target.value);
  };

  const checkFilter = (empresa) => {
    if (Object.keys(empresa).length > 0) {
      return empresa.nome.toLowerCase().includes(filter.toLowerCase());
    }

    return null;
    // return console.log(filter);
  };

  useEffect(() => {
    const results = empresas.filter((empresa) =>
      empresa.nome.toLowerCase().includes(filter.toLowerCase())
    );

    if (results && results.length > 0) {
      setNoCompanies(true);
    } else setNoCompanies(false);
  }, [filter, empresas]);

  console.log(noCompanies);

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div className='background'>
          <div
            style={{
              width: '100vw',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                width: '100%',
                marginTop: '3%',
                marginRight: '3%',
                marginLeft: '3%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '40px' }}>{/* <IoIosArrowBack /> */}</div>

              <img
                style={{ width: '140px' }}
                src='./img/logo/logo.png'
                alt=''
              />

              <div>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <FiUser color='#333' />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open1}
                  onClose={handleCloseMenu}
                >
                  <div>
                    <MenuItem onClick={handleSair}>Sair</MenuItem>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              // background: 'red',
              margin: '40px 10px 0px 10px',
            }}
          >
            <CssTextField
              id='empresa'
              // label='Email'
              placeholder='Empresa'
              name='empresa'
              variant='outlined'
              className='emailInput'
              size='small'
              // value={user.email}
              onChange={handleChange}
              style={{
                background: '#ececec',
                border: 'none',
                width: '90%',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position='start'
                    // style={{ color: '#333' }}
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='start'
                    >
                      <BiSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* {empresas &&
              empresas.map(
                (empresa) =>
                  checkFilter(empresa) && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          width: '90%',
                          justifyContent: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        <Card
                          style={{
                            width: '100%',
                            minHeight: '80px',
                            background: '#ececec',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '10px',
                            boxShadow: 'none',
                          }}
                          onClick={() => handleCompany(empresa.id)}
                        >
                          <div
                            style={{
                              marginLeft: '20px',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#333',
                              }}
                            >
                              {empresa.nome}
                            </p>
                          </div>
                        </Card>
                      </div>
                    </>
                  )
              )} */}
            {noCompanies ? (
              <>
                {empresas &&
                  empresas.map(
                    (empresa) =>
                      checkFilter(empresa) && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              width: '90%',
                              justifyContent: 'center',
                              marginBottom: '20px',
                            }}
                          >
                            <Card
                              style={{
                                width: '100%',
                                minHeight: '80px',
                                background: '#ececec',
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '10px',
                                boxShadow: 'none',
                              }}
                              onClick={() => handleCompany(empresa.id)}
                            >
                              <div
                                style={{
                                  marginLeft: '20px',
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                  }}
                                >
                                  {empresa.nome}
                                </p>
                              </div>
                            </Card>
                          </div>
                        </>
                      )
                  )}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Sem resultados disponíveis.
                </div>
              </>
            )}
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default CompaniesList;
