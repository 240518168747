import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const RecuperarSenha =
  (ydata, handleOpenAlert, navigate, counter, setCounter) =>
  async (dispatch) => {
    await axios
      .post(`${BASE_URL}/app/utilizadores/recuperarsenha`, { ydata })
      .then(({ data }) => {
        setCounter({
          ...counter,
          running: true,
        });
      })
      .then(() => handleOpenAlert('green'))
      .catch(({ err }) => {
        handleOpenAlert('yellow');
      });
  };
export default RecuperarSenha;
