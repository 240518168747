/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import '../recuperarSenha.css';
import { useNavigate, Link } from 'react-router-dom';

//PACKAGES
import { Button, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

//ACTIONS
import RecuperarSenha from '../../../../utils/redux/actions/senha/recuperarSenhaAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});
// const Alert = (props) => {
//   return <MuiAlert elevation={6} variant='filled' {...props} />;
// };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#333',
  backgroundColor: 'white',
  border: '1px solid white',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#333',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

const CssTextField = styled(TextField)({
  borderRadius: '10px',
  color: 'white !important',
  '& label.Mui-focused': {
    color: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& input': {
      // paddingLeft: '10px !important',
      background: '#4fa3b1',
      // opacity: 0.3,
      borderRadius: '10px',
      color: 'white',
    },
    '& fieldset': {
      // color: 'white !important',
      // color: '#333 !important',
    },

    '&:hover fieldset': {
      borderColor: 'white !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white !important',
    },
  },
});

const FormRecuperarSenha = ({ validateOnChange = true, dispatch }) => {
  const [user, setUser] = useState({
    email: '',
  });

  const navigate = useNavigate();

  //   useEffect(() => fetch(), []);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues)
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const [counter, setCounter] = useState({
    time: 5,
    do: false,
    complete: false,
    running: false,
  });

  const resetCounter = () => {
    setCounter({
      time: 5,
      do: false,
      complete: false,
      running: false,
    });
  };

  useEffect(() => {
    if (counter.running == true) {
      let finished = false;
      if (counter.time === 0) {
        finished = true;
        navigate('/');
        return resetCounter();
      }
      counter.time > 0 &&
        setTimeout(
          () =>
            setCounter({
              ...counter,
              time: counter.time - 1,
              complete: finished,
            }),
          1000
        );
    }
  }, [counter.time, counter.running]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationUser()) {
      const ydata = [
        {
          email: user.email,
        },
      ];

      dispatch(
        RecuperarSenha(ydata, handleOpenAlert, navigate, counter, setCounter)
      );
    }
  };

  return (
    <>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Snackbar
          open={openSnackGreen}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          message='Note archived'
          // action={action}
        >
          <Alert onClose={handleCloseAlert} severity='success'>
            Senha recuperada! Verifique o seu email. <br />
            Redirencionando ({counter.time}s)
          </Alert>
        </Snackbar>

        <Snackbar
          open={openSnackYellow}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity='warning'>
            Verifique se o mail está correto.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackRed}
          autoHideDuration={3000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity='danger'>
            As passwords nao são iguais!
          </Alert>
        </Snackbar>
      </Stack>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <form
            className='loginForm'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <CssTextField
              id='email'
              name='email'
              label='Email'
              variant='outlined'
              className='emailInput'
              size='small'
              value={user.email}
              onChange={handleChange}
              {...(errorsUser.email && {
                error: true,
                helperText: errorsUser.email,
              })}
            />
            <ColorButton
              type='submit'
              style={{ margin: 'auto', marginTop: '10px' }}
            >
              Recuperar Senha
            </ColorButton>
            <Link to='/' style={{ color: 'white', textAlign: 'end' }}>
              <p style={{ fontSize: '14px' }}>Voltar para o Login</p>
            </Link>
          </form>
        </div>
      </ThemeProvider>
    </>
  );
};

export default FormRecuperarSenha;
