/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//ACTIONS
import DadosSenhaUtilizador from '../../../utils/redux/actions/senha/dadosSenhaAction';
import SenhaJaAssociada from './components/senhaJaAssociada';
import FormDefinirSenha from './components/formDefinirSenha';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const DefinirSenha = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];
  const [res, setRes] = useState(null);
  const fetchHash = () => {
    dispatch(DadosSenhaUtilizador(hash, setRes));
  };
  const dados = useSelector((state) => state.senha.dadosDefSenha);

  useEffect(() => fetchHash(), []);

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        navigate('/')
      ) : (
        <ThemeProvider theme={defaultMaterialTheme}>
          <div className='home'>
            <div className='home-left'>
              {/* <img src={Logo} alt='' className='logo' /> */}
              <div className='login-form'>
                <div className='titulo'>
                  <img
                    style={{ width: '100%' }}
                    src='../img/logo/logo_light.png'
                    alt=''
                  />
                </div>

                {dados === undefined ? (
                  fetchHash()
                ) : (
                  <div style={{ width: '100%', marginTop: '18vh' }}>
                    {(dados && dados.length === 0) || res === 404 ? (
                      <SenhaJaAssociada
                        onSubmit
                        form='log_in_form'
                        dispatch={dispatch}
                      />
                    ) : (
                      <>
                        <FormDefinirSenha
                          onSubmit
                          form='log_in_form'
                          dispatch={dispatch}
                          dados={dados}
                          // fetch={fetch}
                          hash={hash}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className='bottom'></div>
            </div>
            {/* <div className='home-right'></div> */}
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default DefinirSenha;
