import './App.css';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

//Components
import Companies from './pages/Companies';
import QrCode from './pages/QrCode';
import Error404 from './pages/Global/Error/error';
import Login from './pages/Global/Login/login';
import RecuperarSenha from './pages/Global/recuperarSenha/recuperarSenha';
import DefinirSenha from './pages/Global/definirSenha/definirSenha';

import ProtectedRoute from './Router/ProtectedRoute';

const App = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className='App'>
      <Routes>
        <Route exact path='/' element={<Login />} />

        {/* OPENED ROUTES */}
        <Route path='/definirsenha/:hash' element={<DefinirSenha />} />
        <Route path='/recuperarSenha' element={<RecuperarSenha />} />
        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoute isAllowed={user} />}>
          <Route path='/empresas' element={<Companies />} />
          <Route path='/empresa' element={<QrCode />} />
          <Route path='/empresa/:empresa' element={<QrCode />} />
        </Route>

        <Route path='*' element={<Error404 />} />
      </Routes>
    </div>
  );
};

export default App;
